
    export default {
        data() {
            return {
                isDragging : false,
                startdragX : 0,
                initalDeltaX : 0,
                delta : 0,
                animateHolder : null,
                internalMarginLeft : 0
            }
        },
        props : ['blok','count','currentIndex','marginLeft','width','draggableForScroll','nohover'],
        mounted(){
            this.initDrag();
        },
        beforeDestroy(){
            if(this.animateHolder) cancelAnimationFrame(this.animate);
            this.$refs.bar.removeEventListener("mousedown",this.handleDragStart)
            this.$refs.bar.removeEventListener("touchstart",this.handleDragStart)
            window.removeEventListener("mouseup",this.handleDragStop);
            window.removeEventListener("touchend",this.handleDragStop);

        },
        computed :{
            mouseX : {
                get(){
                    return this.$store.state.mouse.realx;
                }
            },
        },
        methods: {
            initDrag(){
                if(this.draggableForScroll){
                    if(this.$parent.$el && this.$refs.bar){
                        this.$refs.bar.addEventListener("mousedown",this.handleDragStart, { passive : true });
                        this.$refs.bar.addEventListener("touchstart",this.handleDragStart, { passive : true });
                        this.animate();
                    }else{
                        this.$lerpfunction(this.initDrag,100,this);
                    }
                }
            },
            handleDragStart(){
                this.isDragging = true;
                this.startdragX = parseInt(this.mouseX);
                this.initalDeltaX = (parseFloat(this.marginLeft) / 100) * this.$el.clientWidth;
                window.addEventListener("mouseup",this.handleDragStop, { passive : true });
                window.addEventListener("touchend",this.handleDragStop, { passive : true });
            },
            handleDragStop(){
                this.isDragging = false; 
                this.startdragX = this.delta;
                window.removeEventListener("mouseup",this.handleDragStop);
                window.removeEventListener("touchend",this.handleDragStop);
            },
            animate(){
                if(this.isDragging){
                    const deltaMouseX = this.initalDeltaX + (this.mouseX - this.startdragX);
                    var scrollPane = this.$parent.$refs.scrollPane;
                    scrollPane.scrollLeft = deltaMouseX * (scrollPane.clientWidth / this.$refs.bar.clientWidth);
                }
                this.animateHolder = requestAnimationFrame(this.animate);
            }
        },
    }
